import axios from "axios";
import Api from "./Api";
import { app } from "@/main.js";
import store from "@/store";
class MembershipApi extends Api {
  constructor() {
    super("membership", "membership");
  }

  async getList(userId) {
    // const errorText = "Renew Subscription failed";
    const errorText = app.$i18n.t("errorMessage.membershipApi.renewSubscription");

    let result;
    try {
      let url = `/${this.endpoint}/list/${userId}`;
      result = await axios.get(url);

      if (result.status === 200) {
        return result.data;
      }
      return { error: errorText };
    } catch (e) {
      console.log(e);
      if (e.response.status === 400 && e.response.data.error) {
        return e.response.data;
      }
      return { error: errorText };
    }
  }

  async register(hostedCheckoutData) {
    // const errorText = "Payment failed";
    const errorText = app.$i18n.t("errorMessage.membershipApi.payment");
    const locale = store.getters.language;

    hostedCheckoutData.locale = locale;
    let result;
    try {
      let url = `/${this.endpoint}`;
      result = await axios.post(url, hostedCheckoutData);

      if (result.status === 200) {
        return result.data;
      }
      return { error: errorText };
    } catch (e) {
      console.log(e);
      if (e.response.status === 400 && e.response.data.error) {
        return e.response.data;
      }
      return { error: errorText };
    }
  }
}

export default MembershipApi;
