import axios from "axios";
import Api from "./Api";
import { app } from "@/main.js";

class IngenicoApi extends Api {
  constructor() {
    super("ingenico", "ingenico");
  }

  async hostedCheckout(renewData) {
    // const errorText = "Renew Subscription failed";
    const errorText = app.$i18n.t("errorMessage.ingenicoApi.renewSubscription");

    let result;
    try {
      let url = `/${this.endpoint}/hostedCheckout`;
      result = await axios.post(url, renewData);

      if (result.status === 200) {
        return result.data;
      }
      return { error: errorText };
    } catch (e) {
      console.log(e);
      if (e.response.status === 400 && e.response.data.error) {
        return e.response.data;
      }
      return { error: errorText };
    }
  }
  async getCheckoutStatus(hostedCheckoutData) {
    // const errorText = "Payment failed";
    const errorText = app.$i18n.t("errorMessage.ingenicoApi.payment");
    let result;
    try {
      let url = `/${this.endpoint}/hostedCheckoutStatus`;
      result = await axios.post(url, hostedCheckoutData);

      if (result.status === 200) {
        return result.data;
      }
      return { error: errorText };
    } catch (e) {
      console.log(e);
      if (e.response.status === 400 && e.response.data.error) {
        return e.response.data;
      }
      return { error: errorText };
    }
  }
}

export default IngenicoApi;
