<template>
  <v-container id="receipt-wrap" v-if="isOpen">
    <v-card>
      <v-card-title style="padding-bottom: 0px">
        <v-row>
          <v-col cols="9">{{ $t("payment.paymentReceipt.creditCardPayment") }}</v-col>
          <v-col cols="3" class="text-right">
            <v-btn icon @click="close"><v-icon>mdi-close</v-icon></v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row class="font-weight-bold">
          <v-col>{{ thankYouMessage }}</v-col>
        </v-row>
        <v-row v-for="item in dataOutput" :key="item.id">
          <v-col cols="4">{{ item.id }}:</v-col><v-col>{{ item.value }}</v-col>
        </v-row>
        <v-divider class="mt-1"></v-divider>
        <v-row class="mt-1 font-weight-bold text-subtitle-1">
          <v-col class="pb-0" cols="4">{{ totalAmount.id }}</v-col>
          <v-col class="pb-0">{{ totalAmount.value }}</v-col>
          <v-col class="pb-0 text-right">
            <v-btn outlined color="primary" @click="print">{{
              $t("payment.paymentReceipt.print")
            }}</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment";
export default {
  name: "Receipt",
  props: {
    createdPaymentOutput: {
      type: Object,
    },
    type: {
      type: String,
    },
  },
  data: () => ({
    isOpen: false,
  }),
  methods: {
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
      this.$emit("receipt-closed");
    },
    print() {
      window.print();
    },
  },
  computed: {
    isCreatedPaymentOutput() {
      return Object.keys(this.createdPaymentOutput).length > 0;
    },
    thankYouMessage() {
      if (this.type === "membership")
        return this.$t("payment.paymentReceipt.thankYouMessage.membership");
      else if (this.type === "event")
        return this.$t("payment.paymentReceipt.thankYouMessage.event");
      else return "";
    },
    dataOutput() {
      if (!this.createdPaymentOutput) return false;
      let dataOutput = [];

      this.membershipUdfName ? dataOutput.push(this.membershipUdfName) : "";
      this.fullName ? dataOutput.push(this.fullName) : "";
      this.companyName ? dataOutput.push(this.companyName) : "";
      this.datePurchased ? dataOutput.push(this.datePurchased) : "";
      this.notes ? dataOutput.push(this.notes) : "";
      this.cardName ? dataOutput.push(this.cardName) : "";
      this.cardNumber ? dataOutput.push(this.cardNumber) : "";
      this.orderStatus ? dataOutput.push(this.orderStatus) : "";
      this.orderNumber ? dataOutput.push(this.orderNumber) : "";

      return dataOutput;
    },
    membershipUdfName() {
      if (this.isCreatedPaymentOutput) {
        if (this.createdPaymentOutput.membershipUdfName) {
          return {
            id: this.$t("payment.paymentReceipt.item"),
            value: this.createdPaymentOutput.membershipUdfName,
          };
        }
      }
      return false;
    },
    fullName() {
      if (this.isCreatedPaymentOutput) {
        if (this.createdPaymentOutput.customerfullName) {
          return {
            id: this.$t("payment.paymentReceipt.fullName"),
            value: this.createdPaymentOutput.customerfullName,
          };
        }
      }
      return false;
    },
    companyName() {
      if (this.isCreatedPaymentOutput) {
        if (this.createdPaymentOutput.companyName) {
          return {
            id: this.$t("payment.paymentReceipt.companyName"),
            value: this.createdPaymentOutput.companyName,
          };
        }
      }
      return false;
    },
    orderStatus() {
      if (this.isCreatedPaymentOutput) {
        if (this.createdPaymentOutput.paymentStatus) {
          return {
            id: this.$t("payment.paymentReceipt.orderStatus"),
            value: this.createdPaymentOutput.paymentStatus,
          };
        }
      }
      return false;
    },
    cardNumber() {
      if (this.isCreatedPaymentOutput) {
        if (this.createdPaymentOutput.cardNumber) {
          return {
            id: this.$t("payment.paymentReceipt.cardNumber"),
            value: this.createdPaymentOutput.cardNumber,
          };
        }
      }
      return false;
    },
    cardName() {
      if (this.isCreatedPaymentOutput) {
        if (this.createdPaymentOutput.cardName) {
          return {
            id: this.$t("payment.paymentReceipt.cardName"),
            value: this.createdPaymentOutput.cardName,
          };
        }
      }
      return false;
    },
    orderNumber() {
      if (this.isCreatedPaymentOutput) {
        if (this.createdPaymentOutput.orderNumber) {
          return {
            id: this.$t("payment.paymentReceipt.orderNumber"),
            value: this.createdPaymentOutput.orderNumber,
          };
        }
      }
      return false;
    },
    datePurchased() {
      if (this.isCreatedPaymentOutput) {
        if (this.createdPaymentOutput.datePurchased) {
          return {
            id: this.$t("payment.paymentReceipt.datePurchased"),
            value: this.createdPaymentOutput.datePurchased,
          };
        }
      }
      return false;
    },
    notes() {
      if (!this.isCreatedPaymentOutput || !this.createdPaymentOutput.notes) return false;
      return {
        id: this.$t("payment.paymentReceipt.notes"),
        value: this.createdPaymentOutput.notes,
      };
    },
    totalAmount() {
      if (!this.isCreatedPaymentOutput || !this.createdPaymentOutput.amount) return false;
      let currency = "$";
      //let amount = String(this.createdPaymentOutput.amount_of_money);
      //let fixedAmount = amount.slice(0, -2) + "." + amount.slice(-2);
      switch (this.createdPaymentOutput.currencyCode) {
        case "USD":
          currency = "$";
          break;
        case "CAD":
          currency = "$";
          break;
        case "EUR":
          currency = "€";
        default:
          break;
      }
      return {
        id: this.$t("payment.paymentReceipt.totalAmount"),
        value: `${currency} ${this.createdPaymentOutput.amount}`,
      };
    },
  },
};
</script>

<style lang="scss">
// Hide all elements except receipt on page print
@media print {
  body * {
    visibility: hidden;
  }
  #receipt-wrap,
  #receipt-wrap * {
    visibility: visible;
  }
  #receipt-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
