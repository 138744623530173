import axios from "axios";
import Api from "./Api";
import { app } from "@/main.js";

class PaymentApi extends Api {
  constructor() {
    super("payment", "payment");
  }

  async getReceipt(receiptData) {
    const errorText = app.$i18n.t("errorMessage.paymentApi.receipt");

    let result;
    try {
      let url = `/${this.endpoint}/receipt`;
      result = await axios.post(url, receiptData);

      if (result.status === 200 && !result.data.error) return result.data;
      return { error: errorText };
    } catch (e) {
      console.log(e);
      if (e.response.status === 400 && e.response.data.error) {
        return e.response.data;
      }
      return { error: errorText };
    }
  }

  async getReceiptById(receiptData) {
    const errorText = app.$i18n.t("errorMessage.paymentApi.receipt");

    let result;
    try {
      let url = `/${this.endpoint}/receipt-by-id`;
      result = await axios.post(url, receiptData);

      if (result.status === 200) return result.data;
      return { error: errorText };
    } catch (e) {
      console.log(e);
      if (e.response.status === 400 && e.response.data.error) {
        return e.response.data;
      }
      return { error: errorText };
    }
  }
}

export default PaymentApi;
