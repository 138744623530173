<template>
  <v-container pa-0 ma-0 fluid>
    <v-row v-if="isShowDonationThankYouNote" class="subtitle-1"
      ><v-col
        cols="12"
        class="font-weight-bold font-italic"
        v-html="$t('payment.paymentReceipt.donation.thankYouNote')"
      ></v-col>
      <v-col
        cols="12"
        class="font-weight-bold mt-0 pt-0"
        v-html="$t('payment.paymentReceipt.donation.signature')"
      ></v-col>
    </v-row>
    <payment-receipt
      :createdPaymentOutput="createdPaymentOutput"
      :type="type"
      ref="paymentReceipt"
      @receipt-closed="closePayment"
    ></payment-receipt>
    <v-form v-if="isShowMembershipCheckoutDetails" ref="paymentsForm" v-model="valid">
      <v-card style="width: 100%">
        <v-card-title>
          <v-row>
            <v-col>{{ $t("payment.payment.renew") }} {{ campaignName }}</v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row class="font-weight-bold">
            <v-col cols="12" md="6" class="py-0">
              <v-row class="fill-height align-end" no-gutters>
                <v-col cols="12" class="py-0">
                  <div class="mb-3">
                    {{ $t("payment.payment.membershipAmountMessage") }}
                  </div>
                  <v-text-field
                    v-model="amount"
                    :label="$t('payment.payment.amount')"
                    outlined
                    prefix="€"
                    type="number"
                    step="0.01"
                    :rules="amountRules"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-textarea
                v-model="note"
                :label="$t('payment.payment.notes')"
                outlined
                hide-details
                style="padding-bottom: 30px"
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="12" class="mx-3">
              <v-row class="subtitle-1 font-weight-bold">
                {{ $t("payment.payment.process.paymentMessage") }}:</v-row
              >
              <v-row>{{ $t("payment.payment.process.company") }}</v-row>
              <v-row>{{ $t("payment.payment.process.companyAddress") }}</v-row>
              <v-row
                >{{ $t("payment.payment.process.federalGovernmentRegistrationNumber") }}
                {{ federalGovernmentNumber }}
              </v-row>
            </v-col>
            <v-col cols="12" md="12" class="mx-0">
              <v-img
                contain
                max-height="35px"
                max-width="145px"
                class="mr-auto"
                src="@/assets/visa-and-mastercard-logo.png"
              ></v-img>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col cols="12" class="text-center">
              <v-btn color="success" @click="openHostedCheckout">{{
                $t("payment.payment.button.checkout")
              }}</v-btn>
            </v-col>
            <v-col cols="12" class="text-center">
              <v-btn color="primary" text @click="closePayment">
                {{ $t("payment.payment.button.cancel") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-form>
    <v-form v-if="isShowDonationCheckoutDetails" ref="donationCheckoutForm" v-model="valid">
      <v-card style="width: 100%">
        <v-card-text>
          <v-row class="mb-3"
            ><v-col class="subtitle-1"
              ><span class="font-weight-bold">{{ $t("payment.payment.donation.thankYou") }}</span>
              {{ $t("payment.payment.donation.thankYouNote") }}</v-col
            ></v-row
          >
          <v-row class="font-weight-bold">
            <v-col cols="12" md="3" class="py-0">
              <v-text-field v-model="name" outlined :rules="validationRulesNotEmpty">
                <template slot="label">
                  {{ $t("payment.payment.donation.name") }}<RedAsterisk></RedAsterisk> </template
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="py-0">
              <v-text-field v-model="email" outlined :rules="validationRulesEmail"
                ><template slot="label">
                  {{ $t("payment.payment.donation.email") }}<RedAsterisk></RedAsterisk> </template
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" class="py-0">
              <v-text-field
                v-model="companyName"
                :label="$t('payment.payment.donation.organizationName')"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="py-0">
              <v-row class="fill-height align-end" no-gutters>
                <v-col cols="12" class="py-0">
                  <!-- <div class="mb-3">
                    
                  </div> -->
                  <v-text-field
                    v-model="amount"
                    outlined
                    prefix="€"
                    type="number"
                    step="0.01"
                    :rules="amountRules"
                    ><template slot="label">
                      {{ $t("payment.payment.amount") }}<RedAsterisk></RedAsterisk> </template
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="12" class="py-0">
              <v-textarea
                v-model="note"
                :label="$t('payment.payment.notes')"
                outlined
                hide-details
                style="padding-bottom: 30px"
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="12" class="mx-3">
              <v-row class="subtitle-1 font-weight-bold">
                {{ $t("payment.payment.process.donationMessage") }}:</v-row
              >
              <v-row>{{ $t("payment.payment.process.company") }}</v-row>
              <v-row>{{ $t("payment.payment.process.companyAddress") }}</v-row>
              <v-row
                >{{ $t("payment.payment.process.federalGovernmentRegistrationNumber") }}
                {{ federalGovernmentNumber }}
              </v-row>
            </v-col>
            <v-col cols="12" md="12" class="mx-0">
              <v-img
                contain
                max-height="35px"
                max-width="145px"
                class="mr-auto"
                src="@/assets/visa-and-mastercard-logo.png"
              ></v-img>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row mt-0 pt="0">
            <v-col cols="12" class="text-center">
              <v-btn color="success" @click="openHostedCheckout">{{
                $t("payment.payment.button.donationCheckout")
              }}</v-btn>
            </v-col>
            <v-col cols="12" class="text-center">
              <v-btn color="primary" text @click="closePayment">
                {{ $t("payment.payment.button.cancel") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-form>
    <!-- TESTING URL ONLY, CHANGE BACK TO PRODUCTION URL WHEN READY -->
    <form
      METHOD="post"
      :ACTION="this.ingenicoHostedPaymentURL"
      id="form1"
      name="form1"
      target="checkoutIFrame"
      ref="hostedCheckoutForm"
    >
      <!-- ACTION="https://secure.ogone.com/ncol/prod/orderstandard_utf8.asp" -->
      <!-- target="checkoutIFrame" -->
      <input TYPE="hidden" NAME="AMOUNT" :VALUE="amountOfMoney" />
      <input TYPE="hidden" NAME="CURRENCY" :VALUE="currency" />
      <input TYPE="hidden" NAME="LANGUAGE" :VALUE="languageCode" />
      <input TYPE="hidden" NAME="ORDERID" :VALUE="orderId" />
      <input TYPE="hidden" NAME="PSPID" :VALUE="PSPID" />
      <input TYPE="hidden" NAME="SHASIGN" :VALUE="shasign" />

      <!-- <input TYPE="hidden" NAME="version" VALUE="ncol_2.0" /> -->
      <!-- <input TYPE="hidden" NAME="accepturl" VALUE="http://localhost:8443/#/payments" />
      <input TYPE="hidden" NAME="exceptionurl" VALUE="http://localhost:8443/#/payments" />
      <input TYPE="hidden" NAME="declineurl" VALUE="http://localhost:8443/#/payments" />
      <input TYPE="hidden" NAME="cancelurl" VALUE="http://localhost:8443/#/payments" />
      <input TYPE="hidden" NAME="homeurl" VALUE="http://localhost:8443/" /> -->

      <button TYPE="submit" style="display: none" ref="submitBtn">Renew</button>
    </form>

    <v-row v-show="isShowHostedCheckoutIFrame">
      <iframe name="checkoutIFrame" width="100%" height="800" style="border: none"></iframe>
      <!-- <iframe :src="hostedCheckoutURL" width="100%" height="700" style="border: none"></iframe>
       -->
    </v-row>
    <v-progress-circular
      v-show="isPaymentLoading"
      class="loading-indicator"
      :size="70"
      :width="7"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </v-container>
</template>

<script>
import axios from "axios";
import Bus from "@/bus";
import IngenicoApi from "@/api/IngenicoApi";
import MembershipApi from "@/api/MembershipApi";
import DonationApi from "@/api/DonationApi";
import PaymentReceipt from "@/components/Payments/PaymentReceipt";
import { mapGetters } from "vuex";
import { validationRules } from "@/mixins/validationRules";
import RedAsterisk from "@/components/Layout/RedAsterisk";

export default {
  components: {
    PaymentReceipt,
    RedAsterisk,
  },
  props: ["type"],
  mixins: [validationRules],
  name: "Payment",
  data: () => ({
    valid: false,
    isShowMembershipCheckoutDetails: false,
    isShowDonationCheckoutDetails: false,
    amount: "",

    note: "",
    hostedCheckoutURL: "",
    campaignName: "",
    isShowHostedCheckoutIFrame: false,
    isPaymentLoading: false,
    createdPaymentOutput: {},
    ingenicoApi: new IngenicoApi(),
    membershipApi: new MembershipApi(),
    donationApi: new DonationApi(),
    name: "",
    companyName: "",
    email: "",
    isShowDonationThankYouNote: false,
    federalGovernmentNumber: "876 462 801",
    amountOfMoney: "",
    currency: "",
    orderId: "",
    PSPID: "",
    languageCode: "",
    shasign: "",
  }),
  computed: {
    ...mapGetters(["user", "language"]),
    amountNum() {
      if (this.amount) {
        return this.amount * 100;
      }
      return "";
    },
    amountRules() {
      return [
        (v) =>
          RegExp("^[0-9]+(.[0-9]{1,2})?$").test(v) ||
          this.$t("payment.payment.rule.amount.invalidCurrency"),
        (v) => !!v || this.$t("payment.payment.rule.amount.required"),
      ];
    },
    ingenicoHostedPaymentURL() {
      return process.env.VUE_APP_INGENICO_HOSTED_PAYMENT_URL;
    },
  },
  methods: {
    async openHostedCheckout() {
      if (this.type === "membership" && !this.$refs.paymentsForm.validate()) return;
      if (this.type === "donation" && !this.$refs.donationCheckoutForm.validate()) return;

      this.isPaymentLoading = true;
      this.isShowMembershipCheckoutDetails = false;
      this.isShowDonationCheckoutDetails = false;
      const result = await this.ingenicoApi
        .hostedCheckout(this.getPaymentData())
        .then((result) => {
          if (result.error) {
            Bus.$emit("alert", "error", result.error);
            this.isShowHostedCheckoutIFrame = false;
            this.isPaymentLoading = false;
            this.$emit("payment-closed");
            return;
          } else {
            this.isPaymentLoading = false;
            this.isShowHostedCheckoutIFrame = true;
            //get orderid
            this.amountOfMoney = result.amount;
            this.currency = result.currency;
            this.languageCode = result.language;
            this.orderId = result.orderID;
            this.PSPID = result.pspid;
            this.shasign = result.SHASign;
          }
        })
        .then((x) => {
          this.$refs.submitBtn.click();
        });
    },
    openMembershipCheckoutDetails(udfKey, campaignName) {
      this.udfKey = udfKey;
      this.campaignName = campaignName;
      this.amount = "";
      this.note = "";
      this.isShowMembershipCheckoutDetails = true;
    },
    openEventCheckout(udfKey, udfName, amount) {
      this.udfKey = udfKey;
      this.campaignName = udfName;
      this.amount = amount;
      this.note = "";
      this.openHostedCheckout();
    },
    openDonationCheckoutDetails() {
      this.udfKey = "";
      this.campaignName = "Donation";
      this.amount = "";
      this.note = "";
      this.name = "";
      this.email = "";
      this.companyName = "";
      this.isShowDonationCheckoutDetails = true;
    },

    closePayment() {
      this.isShowMembershipCheckoutDetails = false;
      this.isShowDonationCheckoutDetails = false;
      this.isShowDonationThankYouNote = false;
      this.$emit("payment-closed");
    },
    async getCheckoutStatus(hostedCheckoutData) {
      if (hostedCheckoutData) {
        hostedCheckoutData.userId = (await this.user.id) ?? null;

        this.isPaymentLoading = true;
        var result;
        if (this.type == "donation") result = await this.donationApi.register(hostedCheckoutData);
        else result = await this.membershipApi.register(hostedCheckoutData);

        this.isPaymentLoading = false;

        if (!result.status) {
          Bus.$emit("alert", result.error_severity, result.error_message);
          this.$emit("payment-closed");
          return;
        } else if (result.paymentReceipt.paymentStatus == "Authorized" && this.type == "donation") {
          this.isShowDonationThankYouNote = true;
        }

        this.createdPaymentOutput = result.paymentReceipt;

        this.$refs.paymentReceipt.open();
      }
    },
    getPaymentData() {
      // let invoiceDescription = this.campaignName;
      // if (this.type === "event") invoiceDescription = this.campaignName.split(";")[0];
      let locale = "en_US";
      switch (this.language) {
        case "es":
          locale = "es_ES";
          break;
        case "fr":
          locale = "fr_FR";
          break;
      }

      return {
        amount: this.amountNum,
        currency: "EUR",
        language: locale,
        userId: this.user.id ?? null,
        abUdfKey: this.udfKey,
        note: this.note,
        type: this.type,
        name: this.name,
        email: this.email,
        companyName: this.companyName,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-indicator {
  $size: 70px;
  position: absolute;
  top: calc(50% - #{$size} / 2);
  left: calc(50% - #{$size} / 2);
}
</style>
